<template>
<v-container grid-list-md text-xs-center>
    <v-layout row wrap justify-center>
        <!-- <Dekreuen /> -->
        <Fokteven />
    </v-layout>

</v-container>

</template>
<script>
import Fokteven from './fokteven.vue';
// import Dekreuen from './dekreuen.vue'; turned off for now

export default {
  name: 'Doglist',
  components: { Fokteven },
  data() {
    return {

    };
  },
};
</script>
<style lang="less" scoped>
.container{
  padding: 0px;


}
</style>
