<template>
<div class="onzeHonden">
  <Doglist />
</div>
</template>
<script>
import Doglist from '@/components/OnzeHonden/Doglist.vue';

export default {
  name: 'onzeHonden',
  components: {
    Doglist,
  },
};
</script>
