<template>
    <v-container :class="paralax" class="onzeteven">
    <h2 class="display-3 mt-5 mb-5">Onze Teven</h2>
      <v-flex v-for="(teef, i) in Ourdogs.teven" :key="i" xs12 justify-center>
          <v-flex xs12>
              <v-layout py-5 row v-bind:class="{'reverse' : i % 2 !== 0, wrap}">
                <v-flex xs12 md4 :class="mobile">
                <div>
                  <h3 class="headline name" mb-01>
                    {{ teef.name }}
                  </h3>
                </div>
                  <div class="body-2 text">
                    <li><span>Geboren: </span><span>{{ teef.birthdate }}</span></li>
                    <li><span>Schouder hoogte: </span><span>{{ teef.shoulderheight }}</span></li>
                    <li v-if="teef.certificate">
                      <span>Certificaten: </span>
                      <span>{{ teef.certificate }}</span>
                    </li>
                    <li v-if="teef.diploma">
                      <span>Diploma: </span>
                      <span>{{ teef.diploma }}</span>
                    </li>
                    <li v-if="teef.training">
                      <span>Training: </span>
                      <span>{{ teef.training }}</span>
                    </li>
                    <li v-if="teef.ataxia">
                      <span>Ataxia: </span>
                      <span>{{ teef.ataxia }}</span>
                    </li>
                    <li>
                      <span>NHSB: </span>
                      <span>{{ teef.nhsb }}</span>
                    </li>
                  </div>
                </v-flex>
                <v-flex class="dog" xs12 md4 >
                  <img :src="teef.pictureurl">
                  <v-layout justify-center>
                    <v-flex xs12>
                      <v-btn block class="stamboom-btn" color="primary"
                        :href="teef.stamboom" target="_blank">Stamboom
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
          </v-flex>
        </v-flex>
    </v-container>
</template>
<script>
import dogs from '../../data/dogs';

export default {
  data() {
    return {
      Ourdogs: dogs,
    };
  },
  computed: {
    // removes background class on mobile/tablet
    paralax() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'paralax';
      }
      return 'mobile';
    },
    mobile() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'offset-xs2 align-center justify-center text-xs-left';
      }
      return '';
    },
    wrap() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return '';
      }
      return 'wrap';
    },
  },
};

</script>
<style lang="less">
.container{
  max-width: 100%;
}
.onzeteven{
  color:white;
  max-width: 100%!important;
  margin-top: 2rem;
    &.mobile{
          color: black!important;
          padding:0px;
          h2, h3{
            color:#858585!important;
          }
        }

h2,h3 {
  color: white!important;
}
.text{
  padding-bottom: 50px;
  padding-top:15px;
  font-size:15px!important;
  }
    &.paralax{
    background-image: url("/img/Molen_Overdag.jpg");
    /* parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    }

    .name{
      font-size:30px!important;
      color:white;


    }
    .dog{
      padding: 0px!important;

      .stamboom-btn{
        margin: 3%;
        width: 95%;
      }
    }
}
    img{
          width: 100%;
    }

li{
  list-style: none;
  font-size: 19px;
}

</style>
