const dogs = {
  teven: [
    {
      id: 1,
      name: 'Quincy van de Meulenbarg',
      active: 1,
      birthdate: '1 November 2017',
      shoulderheight: '62',
      gender: 'teef',
      state: 'adult',
      stamboom: 'https://www.bloedlijnen.nl/?BRN=41401',
      certificate: 'HD. A',
      diploma: 'VZH, IGP',
      training: '',
      ataxia: '(SDCA1) N/N & (SDCA2) N/N',
      nhsb: '3101736',
      pictureurl: '../img/onzehonden_image/Quincy_web.jpg',
      description: 'Your text here',
      youtubevideo: '',
    },
    {
      id: 2,
      name: 'Shiva van de Meulenbarg',
      active: 1,
      birthdate: '2 juni 2020',
      shoulderheight: '62',
      gender: 'teef',
      state: 'adult',
      stamboom: 'https://www.working-dog.com/dogs-details/7563165/Shiva-van-de-Meulenbarg',
      certificate: 'HD. A',
      diploma: 'VZH, klaar voor examen IGP',
      training: '',
      ataxia: '(SDCA1) N/N & (SDCA2) N/P',
      nhsb: '3197898',
      pictureurl: '../img/onzehonden_image/Shiva_Web.jpg',
      description: 'Your text here',
      youtubevideo: '',
    },
  ],
  reuen: [
    {
      id: 5,
      name: "Germali's Nero",
      active: 1,
      birthdate: '29 Mei 2014',
      shoulderheight: '63',
      back: 'perfect',
      gender: 'reu',
      state: 'adult',
      stamboom: 'https://us.working-dog.com/dogs-details/2632474/Germalis-Nero',
      certificate: 'HD: A',
      diploma: 'IGP III',
      training: '',
      ataxia: '(SDCA1) N/N & (SDCA2) N/N',
      nhsb: '3004470',
      pictureurl: '../img/onzehonden_image/Germalis_Web_02.jpg',
      description: 'Your text here',
      youtubevideo: 'https://www.youtube.com/watch?v=z6CpxNRVxfk',
    },
    {
      id: 6,
      name: 'Max van de Lobo Hoeve',
      active: 1,
      birthdate: '24 Augustus 2020',
      shoulderheight: '',
      back: '',
      gender: 'reu',
      state: 'adult',
      stamboom: 'https://www.working-dog.com/dogs-details/7099215/Max-van-de-Lobo-Hoeve',
      certificate: 'HD: A',
      diploma: 'VZH, klaar voor examen IGP',
      training: '',
      ataxia: '(SDCA1) N/N & (SDCA2) N/N',
      nhsb: '3205536',
      pictureurl: '../img/onzehonden_image/max_van_de_Lobohoeve_web.jpg',
      description: 'Your text here',
      youtubevideo: '',
    },
  ],
};
export default dogs;
